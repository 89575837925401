/* Importing the Baloo 2 font */
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600&display=swap');

.driver-popover {
  /* border-radius: 30px !important; */
  padding: 20px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  overflow: hidden;
  animation: rippleEffect 1s ease-out forwards;
  font-family: 'Baloo 2';
}

/* Custom styles for buttons */
.driver-popover button {
  font-family: 'Baloo 2';
  font-size: 1em;
  font-weight: 100;
  color: white;
  background-color: #0be897;
  border: none;
  /* border-radius: 10px; */
  /* padding: 10px 20px; */
  cursor: pointer;
  /* position: relative; */
  overflow: hidden;
  outline: none;
  transition: background-color 0.3s ease;
}

/* Button hover effect */
.driver-popover button:hover {
  background-color: #049ba1;
}

/* Button active (clicked) state with ripple effect */
.driver-popover button:active::after {
  content: '';
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: ripple 0.6s ease-out;
}

/* Ripple animation */
@keyframes ripple {
  to {
    transform: translate(-50%, -50%) scale(4);
    opacity: 0;
  }
}

/* Ensuring text alignment and padding in the popover */
.driver-popover-title {
  margin-bottom: 10px !important;
  font-size: 1.5em !important;
  font-weight: 600 !important;
}

.driver-popover-description {
  font-size: 1.1em !important;
  font-weight: 400 !important;
}

/* Style for the progress bar if used */
.driver-progress-bar {
  background-color: rgba(255, 255, 255, 0.2) !important;
  height: 5px !important;
}

.driver-progress-bar div {
  background-color: white !important;
}
.driver-close-bth button {
  background-color: red;
}
