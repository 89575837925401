.right-bar {
  width: 100%;
  right: 0;
  top: 0;
  bottom: 0;
}

.camera-card {
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 0.7rem;
}

.camera-thumbnail {
  flex: 0 0 25%;
  background-color: #ffffff;
  height: 100px;
  margin-left: 7px;
  border-radius: 4px;
}

.camera-details {
  flex-grow: 1;
  padding: 8px;
  border-radius: 4px;
}

.status-indicators {
  display: flex;
  gap: 2px;
}

.sync-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Aligns the button to the far right */
}

.sync-button .MuiButton-label {
  text-transform: none;
}

.add-camera-button {
  /* display: block; */
  /* margin: auto; */
  width: 80%; /* Full width of the container */
  background-color: #5c6bc0; /* A distinct color for the "Add Camera" button */
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  /* margin-top: 10px; Space above the "Add Camera" button */
}
